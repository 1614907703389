import { TabBar } from '@/components/TabBar'
import { cn } from '@/lib/utils'
import { postEvent } from '@telegram-apps/sdk'
import { useLayoutEffect } from 'react'
import { createPortal } from 'react-dom'
import { useLocation } from 'wouter'

type TabBarWrapperProps = {
  children: React.ReactNode
}

const tabs = ['/friends', '/boosts', '/', '/leagues', '/profile'] as const

export const TabBarWrapper = ({ children }: TabBarWrapperProps) => {
  const [location, navigate] = useLocation()

  useLayoutEffect(() => {
    document.getElementById('wrap')?.scrollTo({ top: 0 })
  }, [location])

  const handleTabClick = (tabIndex: number) => {
    const tab = tabs[tabIndex]
    navigate(tab)
    postEvent('web_app_trigger_haptic_feedback', { type: 'impact', impact_style: 'medium' })
  }

  const isTabBarHidden = location.startsWith('/games/')

  const activeTab =
    location === '/' ? 2 : tabs.findIndex((tab) => tab !== '/' && location.startsWith(tab))

  return (
    <div className={cn('relative flex-1 flex flex-col', isTabBarHidden ? 'pb-4' : 'pb-[90px]')}>
      {children}
      {createPortal(
        <div
          className={cn(
            'fixed left-4 right-4 transition-[bottom] duration-500 z-10',
            isTabBarHidden ? 'bottom-[-70px]' : 'bottom-6'
          )}
        >
          <TabBar activeTab={activeTab} onTabClick={handleTabClick} />
        </div>,
        document.body
      )}
    </div>
  )
}
