import './mockEnv.ts'

import { ATheme } from '@/components/a-theme.tsx'
import { Preloader } from '@/components/Preloader'
import { TabBarWrapper } from '@/components/TabBarWrapper'
import { Providers } from '@/providers/providers'
import { Navigation } from '@/router'
import { BlockLayout } from '@/screens/BlockLayout.tsx'
import '@fontsource-variable/onest'
import '@fontsource-variable/red-hat-mono'
import { postEvent, retrieveLaunchParams } from '@telegram-apps/sdk'
import posthog from 'posthog-js'
import ReactDOM from 'react-dom/client'
import './index.css'
import './lib/sentry'

let erudaInitialized = false

function initEruda() {
  if (erudaInitialized) return
  erudaInitialized = true

  var script = document.createElement('script')
  script.src = 'https://cdn.jsdelivr.net/npm/eruda'
  document.body.append(script)
  script.onload = function () {
    var script = document.createElement('script')
    script.innerText = 'eruda.init()'
    document.body.append(script)
    setTimeout(() => {
      console.log(retrieveLaunchParams().initDataRaw)
    }, 100)
  }
}

if (import.meta.env.DEV) {
  initEruda()
}

// posthog.featureFlags.override({
//   dev: true,
// })

posthog.onFeatureFlags(() => {
  if (posthog.isFeatureEnabled('dev')) {
    initEruda()
  }
})

postEvent('web_app_expand')
postEvent('web_app_setup_swipe_behavior', { allow_vertical_swipe: false })
postEvent('web_app_set_background_color', { color: '#000' })

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Providers>
    <BlockLayout>
      <Preloader>
        <TabBarWrapper>
          <ATheme />
          <Navigation />
        </TabBarWrapper>
      </Preloader>
    </BlockLayout>
  </Providers>
)
