import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query'
import { initCloudStorage } from '@telegram-apps/sdk'

export const cloudStorage = initCloudStorage()

export const useBumpPlayed = () => {
  const queryClient = useQueryClient()

  const { data: bumpPlayed } = useQuery({
    queryKey: ['bump_played'],
    queryFn: () => cloudStorage.get('bump_played'),
  })

  const { mutate: claimBumpPlayed, isPending } = useMutation({
    mutationFn: () => cloudStorage.set('bump_played', '1'),
    onSuccess: () => {
      queryClient.setQueryData(['bump_played'], '1')
      queryClient.invalidateQueries({ queryKey: ['bump_played'] })
    },
  })

  return { bumpPlayed: !!bumpPlayed, claimBumpPlayed, isPending }
}
