'use client'

import { cn } from '@/lib/utils'
import { SpringOptions, useInView, useMotionValue, useSpring } from 'framer-motion'
import { useEffect, useRef } from 'react'

// const defaultFormattingFunction = (value: number) =>
//   Intl.NumberFormat('en-US').format(Math.floor(value))

export const onlyNumbersFormattingFunction = (value: number) => Math.floor(value).toString()

export default function NumberTicker({
  value,
  direction = 'up',
  formattingFunction = onlyNumbersFormattingFunction,
  delay = 0,
  className,
  animationConfig,
}: {
  value: number
  direction?: 'up' | 'down'
  formattingFunction?: (value: number) => string
  className?: string
  delay?: number // delay in s
  animationConfig?: SpringOptions
}) {
  const ref = useRef<HTMLSpanElement>(null)
  const motionValue = useMotionValue(direction === 'down' ? value : 0)
  const springValue = useSpring(
    motionValue,
    animationConfig || {
      // damping: 6,
      // stiffness: 10,
      bounce: 0,
      duration: 300,
    }
  )
  const isInView = useInView(ref, { once: true, margin: '0px' })

  useEffect(() => {
    isInView &&
      setTimeout(() => {
        motionValue.set(direction === 'down' ? 0 : value)
      }, delay * 1000)
  }, [motionValue, isInView, delay, value, direction])

  useEffect(
    () =>
      springValue.on('change', (latest) => {
        if (ref.current) {
          ref.current.textContent = formattingFunction(latest)
        }
      }),
    [springValue, formattingFunction]
  )

  return (
    <div className="relative">
      <span className={cn('tabular-nums absolute right-0', className)} ref={ref}>
        0
      </span>
      <span className="tabular-nums opacity-0">{formattingFunction(value)}</span>
    </div>
  )
}
