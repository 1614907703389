import { useCurrentUserQuery } from '@/api/api'
import Logo from '@/assets/logo.svg?react'
import { EnergyScale } from '@/components/EnergyScale'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { cn } from '@/lib/utils'
import { motion } from 'framer-motion'
import { useLocation } from 'wouter'

type Props = {
  className?: string
}

export function MainHeader({ className }: Props) {
  const [_, navigate] = useLocation()

  const { data: currentUser } = useCurrentUserQuery()

  return (
    <motion.div
      className={cn('flex gap-2 items-center self-stretch', className)}
      initial={{ translateX: '20%', scale: 0.9 }}
      animate={{ translateX: '0%', scale: 1 }}
      transition={{ type: 'spring', damping: 20, stiffness: 400 }}
    >
      <Logo />

      <div className="flex-1" />

      <EnergyScale />

      <Avatar className="size-9" onClick={() => navigate('/profile')}>
        <AvatarImage src={currentUser?.photo_url} />
        <AvatarFallback className="bg-[#5a2c35]">{currentUser?.firstname[0]}</AvatarFallback>
      </Avatar>
    </motion.div>
  )
}
