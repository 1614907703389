import { analytics } from '@/analytics'
import { API_BASE_URL } from '@/constants'
import { DEV_GAMES } from '@/devgames'
import { isDevApp } from '@/lib/utils'
import { showAlert } from '@/providers/AlertProvider'
import type { ClientType } from '@backend/src'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { retrieveLaunchParams } from '@telegram-apps/sdk'
import { ClientResponse, hc, InferRequestType, InferResponseType } from 'hono/client'

let __savedInitDataRaw = retrieveLaunchParams().initDataRaw
if (__savedInitDataRaw) {
  localStorage.setItem('initSampleRaw', __savedInitDataRaw)
}

const client: ClientType = hc(API_BASE_URL, {
  headers() {
    const lp = retrieveLaunchParams()
    __savedInitDataRaw =
      lp.initDataRaw || __savedInitDataRaw || localStorage.getItem('initSampleRaw') || undefined
    return {
      Authorization: `Bearer ${__savedInitDataRaw}`,
    }
  },
}) as any

type ErrorBody = { code: number; message: string } | undefined
export class ApiError extends Error {
  status: number
  body: ErrorBody

  constructor(opts: { status: number; body: ErrorBody }) {
    super('ApiError')
    this.status = opts.status
    this.body = opts.body
  }
}

const wrapResponse = async <T extends ClientResponse<any>>(
  res: Promise<T>
): Promise<T extends ClientResponse<infer D, 200, 'json'> ? Awaited<D> : never> => {
  const response = await res
  if (!response.ok) {
    throw new ApiError({
      status: response.status,
      body: response.headers.get('content-type')?.includes('application/json')
        ? ((await response.json()) as ErrorBody)
        : undefined,
    })
  }
  return response.json() as any
}

// type x = InferResponseType<typeof client.user.currentUser.$get>

export enum QueryKeys {
  currentUser = 'currentUser',
  referrals = 'referrals',
  boosts = 'boosts',
  leaguePlayers = 'leaguePlayers',
  tasks = 'tasks',
  entitiesLeagues = 'entitiesLeagues',
}

const getCurrentUser = async () => {
  const { startParam } = retrieveLaunchParams()
  // throw new ApiError({
  //   status: 401,
  //   body: { code: 1001, message: 'Unauthorized' },
  // })
  return wrapResponse(client.user.currentUser.$get({ query: { start: startParam } }))
}

export type CurrentUserQueryResponse = Awaited<ReturnType<typeof getCurrentUser>>

export const useCurrentUserQuery = () => {
  return useQuery({
    queryKey: [QueryKeys.currentUser],
    queryFn: getCurrentUser,
  })
}

const getReferrals = async () => {
  return wrapResponse(client.user.referrals.$get())
}

export const useReferralsQuery = () => {
  return useQuery({
    queryKey: [QueryKeys.referrals],
    queryFn: getReferrals,
  })
}

export const useReferralClaimMutation = () => {
  const qc = useQueryClient()

  return useMutation({
    mutationFn: ({ referralId }: { referralId: number }) => {
      return wrapResponse(client.user.referrals.reward.$post({ json: { referralId } }))
    },
    onSuccess: (data, variables) => {
      analytics.postEvent('referral_reward_claimed', variables)
      qc.setQueryData([QueryKeys.referrals], data)
      void qc.invalidateQueries({ queryKey: [QueryKeys.currentUser] })
      void qc.invalidateQueries({ queryKey: [QueryKeys.leaguePlayers] })
    },
    onError(error) {
      if (error instanceof ApiError) {
        if (error.body?.code === 1008) {
          showAlert('Referral must achieve silver league', { type: 'info' })
        } else {
          showAlert('Error while claiming referral reward', { type: 'error' })
        }
      }
    },
  })
}

const getLeaguePlayers = async () => {
  return wrapResponse(client.leagues.players.$get())
}

export const useLeaguePlayersQuery = () => {
  return useQuery({
    queryKey: [QueryKeys.leaguePlayers],
    queryFn: getLeaguePlayers,
    staleTime: Infinity,
  })
}

const getPurchasedBoosts = async () => {
  return wrapResponse(client.boosts.purchased.$get())
}

export const usePurchasedBoostsQuery = () => {
  return useQuery({
    queryKey: [QueryKeys.boosts],
    queryFn: getPurchasedBoosts,
  })
}

export const useBoostPurchaseMutation = () => {
  const qc = useQueryClient()

  return useMutation({
    mutationFn: ({ boostId }: { boostId: number }) => {
      return wrapResponse(client.boosts.purchase.$post({ json: { boostId } }))
    },
    onSettled() {
      void qc.invalidateQueries({ queryKey: [QueryKeys.currentUser] })
      void qc.invalidateQueries({ queryKey: [QueryKeys.boosts] })
    },
    onSuccess(data) {
      analytics.postEvent('boost_purchase_success', data)
    },
    onError(error) {
      if (error instanceof ApiError) {
        showAlert('Error while purchasing boost', { type: 'error' })
      }
    },
  })
}

export const sessionRegisterPost = async ({ gameId }: { gameId: number }) => {
  if (isDevApp) {
    const game = DEV_GAMES.find((x) => x.id === gameId)
    if (game) {
      return {
        sessionId: Math.random().toString(),
        url: game.url,
      }
    }
  }
  return wrapResponse(client.session.register.$post({ json: { gameId } }))
}

export const useSessionRegisterMutation = () => {
  const qc = useQueryClient()

  return useMutation({
    mutationFn: sessionRegisterPost,
    onError(error) {
      if (error instanceof ApiError) {
        showAlert('Error while registering session', { type: 'error' })
      }
    },
    onSuccess(data) {
      analytics.postEvent('session_register_success', data)
      qc.invalidateQueries({ queryKey: [QueryKeys.currentUser] })
    },
  })
}

export const getSessionResult = () => {
  return wrapResponse(client.session.results.$get())
}

export const useTasksQuery = () => {
  return useQuery({
    queryKey: [QueryKeys.tasks],
    queryFn: () => wrapResponse(client.tasks.$get()),
  })
}

export type TaskType = InferRequestType<typeof client.tasks.claim.$post>['json']['task']

export type TasksQueryResponse = InferResponseType<typeof client.tasks.$get, 200>

export const useTaskClaimMutation = () => {
  const qc = useQueryClient()

  return useMutation({
    mutationFn: ({ task }: { task: TaskType }) => {
      return wrapResponse(client.tasks.claim.$post({ json: { task } }))
    },
    onSuccess(_, variables) {
      void qc.invalidateQueries({ queryKey: [QueryKeys.currentUser] })
      void qc.invalidateQueries({ queryKey: [QueryKeys.leaguePlayers] })
      void qc.invalidateQueries({ queryKey: [QueryKeys.tasks] })
      analytics.postEvent('task_claimed', variables)
    },
  })
}

export const usePromocodeEnterMutation = () => {
  const qc = useQueryClient()

  return useMutation({
    mutationFn: ({ code }: { code: string }) => {
      return wrapResponse(client.user.promocode.$post({ json: { code } }))
    },
    onSettled() {
      void qc.invalidateQueries({ queryKey: [QueryKeys.currentUser] })
    },
    onSuccess(_, variables) {
      analytics.postEvent('promocode_enter_success', variables)
    },
    onError(error, variables) {
      if (error instanceof ApiError) {
        analytics.postEvent('promocode_enter_error', variables)
        showAlert('Wrong code', { type: 'error' })
      }
    },
  })
}

export const useEntitiesLeaguesQuery = () => {
  return useQuery({
    queryKey: [QueryKeys.entitiesLeagues],
    queryFn: () => wrapResponse(client.entities.leagues.$get()),
    staleTime: Infinity,
    gcTime: Infinity,
  })
}
