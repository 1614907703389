import { useBoostPurchaseMutation, usePurchasedBoostsQuery } from '@/api/api'
import { BottomSheet, BottomSheetClose, BottomSheetGrabber } from '@/components/BottomSheet'
import { MainHeader } from '@/components/MainHeader'
import { Timer } from '@/components/Timer'
import { Spinner } from '@/components/spinner'
import { Button } from '@/components/ui/button'
import { CardButton } from '@/components/ui/card-button'
import { BOOSTS, Boost } from '@/data.app'
import { motion } from 'framer-motion'
import { useState } from 'react'

export function BoostTab() {
  const [selectedBoost, setSelectedBoost] = useState<Boost | null>(null)

  // const { data: currentUser, isLoading: isCurrentUserLoading } = useCurrentUserQuery()

  const {
    data: purchasedBoosts,
    isLoading: isPurchasedBoostsLoading,
    refetch: refetchPurchasedBoosts,
  } = usePurchasedBoostsQuery()

  const { mutate, isPending } = useBoostPurchaseMutation()

  const handlePurchaseBoost = () => {
    if (selectedBoost === null) {
      return
    }

    mutate(
      { boostId: selectedBoost.id },
      {
        onSuccess: () => {
          setSelectedBoost(null)
        },
        onSettled: () => {
          setSelectedBoost(null)
        },
      }
    )
  }

  if (!purchasedBoosts) {
    if (isPurchasedBoostsLoading) {
      return (
        <div className="flex-1 flex items-center justify-center">
          <Spinner />
        </div>
      )
    }
    return null
  }

  return (
    <div className="m-4 flex flex-col gap-4 ">
      <MainHeader />

      <div className="grid grid-cols-1 gap-4">
        {BOOSTS.map((boost) => {
          const purchasedData = purchasedBoosts.find((b) => b.boost_id === boost.id)

          let isAvailable = true
          let timer = null

          if (purchasedData) {
            if (purchasedData.cooldown_at) {
              isAvailable = false
              timer = new Date(purchasedData.cooldown_at * 1000)
            }

            if (purchasedData.expiration_at) {
              isAvailable = false
              timer = new Date(purchasedData.expiration_at * 1000)
            }
          }

          return (
            <motion.div
              key={boost.id}
              initial={{ scale: 0.7 }}
              animate={{ scale: 1 }}
              transition={{ type: 'spring', damping: 20, stiffness: 400 }}
              className="py-[9px] px-[14px] gap-[14px] flex justify-start items-center rounded-[20px] bg-[#141414]"
            >
              <img width={60} height={60} src={boost.icon} />

              <div className="flex-1">
                <div className="font-medium relative">
                  {boost.title}
                  <span className="absolute top-0.5 text-[60%] mb-auto">{boost.postfix}</span>
                </div>

                <div className="mt-1 text-[#fff]/50 flex gap-4">
                  <div>
                    {purchasedData && (
                      <>
                        {purchasedData.max_purchasable_amount - purchasedData.purchased_amount}/
                        {purchasedData.max_purchasable_amount}
                      </>
                    )}
                  </div>
                  <div>{timer && <Timer toDate={timer} onEnd={refetchPurchasedBoosts} />}</div>
                </div>
              </div>

              <CardButton disabled={!isAvailable} onClick={() => setSelectedBoost(boost)}>
                Boost
              </CardButton>
            </motion.div>
          )
        })}
      </div>

      <BottomSheet isOpen={!!selectedBoost} onClose={() => setSelectedBoost(null)}>
        {selectedBoost ? (
          <div className="px-4 pb-8 flex flex-col gap-4">
            <div className="relative bg-[#141414] rounded-[26px] overflow-hidden">
              <BottomSheetClose onClick={() => setSelectedBoost(null)} />

              <div className="absolute w-[110%] h-[110%] left-1/2 -translate-x-1/2 top-[-70%] bg-accent rounded-[50%] opacity-60" />

              <div className=" px-6  pb-10  flex flex-col items-center z-10 rounded-[26px] backdrop-blur-[100px]">
                <BottomSheetGrabber />

                <img src={selectedBoost.icon} className="size-[152px] object-contain mt-8" />

                <div className="mt-[18px] text-center text-2xl font-semibold">
                  {selectedBoost.title}
                </div>

                <div className="mt-2 text-[#fff]/50 text-center font-semibold">
                  {selectedBoost.description}
                </div>
              </div>
            </div>

            <Button
              variant="secondary"
              className="bg-[#141414] font-semibold transition-colors active:bg-[#222222] z-10"
              onClick={handlePurchaseBoost}
              disabled={isPending}
            >
              {isPending && <Spinner className="absolute size-5" />}
              {!isPending ? 'Go!' : <>&nbsp;</>}
            </Button>
          </div>
        ) : null}
      </BottomSheet>
    </div>
  )
}
