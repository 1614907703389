import { postEvent, on } from '@telegram-apps/sdk'
import { useEffect } from 'react'

export const useBackButtonNavigate = () => {
  useEffect(() => {
    const onBack = async () => {
      history.back()
    }

    postEvent('web_app_setup_back_button', { is_visible: true })
    on('back_button_pressed', onBack)

    return () => {
      postEvent('web_app_setup_back_button', { is_visible: false })
    }
  }, [])
}
