import { cn } from '@/lib/utils'
import * as ProgressPrimitive from '@radix-ui/react-progress'
import { AnimationProps, motion } from 'framer-motion'
import * as React from 'react'
import styles from './progress.module.css'

const animationProps: AnimationProps = {
  // @ts-ignore
  initial: { '--x': '100%' },
  // @ts-ignore
  animate: { '--x': '-100%' },
  // whileTap: { scale: 0.95 },
  transition: {
    repeat: Infinity,
    repeatType: 'loop',
    repeatDelay: 1,
    type: 'spring',
    stiffness: 20,
    damping: 15,
    mass: 2,
    scale: {
      type: 'spring',
      stiffness: 200,
      damping: 5,
      mass: 0.5,
    },
  },
}

export const LeagueProgress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>
>(({ className, value, style, ...props }, ref) => (
  <ProgressPrimitive.Root
    ref={ref}
    className={cn('h-[11px] bg-[#141414] rounded-[58px]', className)}
    {...props}
  >
    <ProgressPrimitive.Indicator asChild>
      <motion.div
        {...animationProps}
        className={cn('relative h-full rounded-[58px] transition-all duration-500', styles.tile)}
        style={{ width: `${value}%`, ...style }}
      >
        <span className="absolute inset-0 z-10 block rounded-[inherit] bg-[linear-gradient(-75deg,rgba(255,255,255,0.1)_calc(var(--x)+20%),rgba(255,255,255,0.5)_calc(var(--x)+25%),rgba(255,255,255,0.1)_calc(var(--x)+100%))] p-px"></span>
      </motion.div>
    </ProgressPrimitive.Indicator>
  </ProgressPrimitive.Root>
))
