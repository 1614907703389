import React from 'react'

const handlers = new Map<string, Set<(...args: any[]) => void>>()

declare global {
  namespace EventBus {
    interface EventBusEvents {}
  }
}

const subscribe = <T extends keyof EventBus.EventBusEvents>(
  event: T,
  handler: (...args: EventBus.EventBusEvents[T]) => void
) => {
  let set = handlers.get(event)
  if (!set) {
    set = new Set([handler])
    handlers.set(event, set)
  } else {
    set.add(handler)
  }

  return () => {
    if (set.size === 1) {
      handlers.delete(event)
    } else {
      set.delete(handler)
    }
  }
}

const emit = <T extends keyof EventBus.EventBusEvents>(
  event: T,
  ...args: EventBus.EventBusEvents[T]
) => {
  handlers.get(event)?.forEach((handler) => handler(...args))
}

export const eventBus = {
  subscribe,
  emit,
}

const useInsertionEffect = React.useInsertionEffect || React.useLayoutEffect

export const useEventBusEffect = <T extends keyof EventBus.EventBusEvents>(
  event: T,
  cb: (...args: EventBus.EventBusEvents[T]) => void
) => {
  const handler = React.useRef(cb)
  useInsertionEffect(() => {
    handler.current = cb
  }, [cb])

  React.useEffect(() => {
    return subscribe(event, handler.current)
  }, [event])
}
