'use client'

import posthog from 'posthog-js'
import { PostHogProvider as PHP } from 'posthog-js/react'

import { retrieveLaunchParams } from '@telegram-apps/sdk'

if (
  typeof window !== 'undefined' &&
  import.meta.env.PROD &&
  import.meta.env.MODE === 'production'
) {
  const { initData } = retrieveLaunchParams()

  posthog.init('phc_xElBRc7CJp1aOd1q8JJxE1qrEGK3pm0b8RYsgpPAGGA', {
    api_host: 'https://eu.i.posthog.com',
    // ui_host: 'eu.i.posthog.com',

    // disable_compression: true,

    enable_heatmaps: false,
    person_profiles: 'identified_only',
    session_recording: {
      recordCrossOriginIframes: true,
    },
  })

  if (initData?.user?.id) {
    posthog.identify(initData.user.id.toString(), {
      ...initData.user,
      app_version: __APP_VERSION__,
    })
  }
}

export function PostHogProvider({ children }: { children: React.ReactNode }) {
  return <PHP client={posthog}>{children}</PHP>
}
