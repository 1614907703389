import React from 'react'

const useInsertionEffect = React.useInsertionEffect || React.useLayoutEffect

type AnyFunction = (...args: any[]) => any

export function useHandler<T extends AnyFunction>(callback: T): T {
  const latestRef = React.useRef(useEvent_shouldNotBeInvokedBeforeMount)
  useInsertionEffect(() => {
    latestRef.current = callback
  }, [callback])

  const stableRef = React.useRef<any>(null)
  if (!stableRef.current) {
    stableRef.current = function () {
      return latestRef.current.apply(this, arguments as any)
    }
  }
  return stableRef.current
}

function useEvent_shouldNotBeInvokedBeforeMount() {
  throw new Error(
    'INVALID_USEEVENT_INVOCATION: the callback from useEvent cannot be invoked before the component has mounted.'
  )
}
