import { useReferralsQuery, useTasksQuery } from '@/api/api'
import { isFeatureEnabled } from '@/lib/isFeatureEnabled'
import { retrieveLaunchParams } from '@telegram-apps/sdk'

export function useTasks() {
  const { data: referrals } = useReferralsQuery()

  const { data: tasks, isLoading: isTasksLoading } = useTasksQuery()

  const isRussian = retrieveLaunchParams().initData?.user?.languageCode === 'ru'

  const isJoinChatClaimed = isRussian ? tasks?.ruChatSubscription : tasks?.enChatSubscription

  return {
    isTasksLoading,
    tasks,
    isReadyForClaim: tasks
      ? {
          telegram: !tasks.telegramSubscription,
          twitter: !tasks.twitterSubscription,
          friends: !tasks.referrals && (referrals?.length || 0) >= 3,
          dailyReward: !!tasks.dailyReward.cooldown,
          boost: !!tasks.boostTask.completed_at,
          emoji: !!tasks.emojiTask.completed_at,
          joinChat: isFeatureEnabled('joinChat') && !isJoinChatClaimed,
          bumpSubscription: isFeatureEnabled('bump') && !tasks.bumpSubscription,
          bumpPlayed: isFeatureEnabled('bump') && !tasks.bumpPlayed,
          beeVerseSubscription: isFeatureEnabled('bee') && !tasks.beeVerseSubscription,
          beeVersePlayed: isFeatureEnabled('bee') && !tasks.beeVersePlayed,
          lizArtsSubscription: isFeatureEnabled('lizarts') && !tasks.lizArtsSubscription,
          lizArtsPlayed: isFeatureEnabled('lizarts') && !tasks.lizArtsPlayed,
          bbqCoinPlayed: isFeatureEnabled('bbq') && !tasks.bbqCoinPlayed,
          meerkatPlayed: isFeatureEnabled('meerkat') && !tasks.meerkatPlayed,
          gemseePlayed: isFeatureEnabled('gemsee') && !tasks.gemseePlayed,
          durovRunPlayed: isFeatureEnabled('durovrun') && !tasks.durovRunPlayed,
          broskiPlayed: isFeatureEnabled('broski') && !tasks.broskiPlayed,
          realSpinPlayed: isFeatureEnabled('real_spin') && !tasks.realSpinPlayed,
          teaFarmPlayed: isFeatureEnabled('tea_farm') && !tasks.teaFarmPlayed,
        }
      : null,
  }
}
